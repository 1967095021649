import Link                from 'next/link';
import {useProductEvents}  from 'hooks';
import {useTranslation}    from "next-i18next";
import styles              from './ready-start-section.module.scss';
import {
  OptimizelyExperiment,
  OptimizelyVariation,
  withOptimizely,
}                          from '@optimizely/react-sdk';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

function ReadyStartSection ({optimizely}) {
  const { t } = useTranslation('landing');
  const events = useProductEvents();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.text_wrapper}>
          <div className="section-title">
            <span className="extra-bold">{t('sections.readyStart.title')}</span>
          </div>
          <div className={styles.description}>
            {t('sections.readyStart.subtitle')}
          </div>
          <div className={styles.action_container}>
            <div className={styles.action}>
              <OptimizelyExperiment experiment="zen_car_workshop_landing_try_demo_test">
                <OptimizelyVariation variation="variation_try_free">
                  <Link href="/access?tab=signup">
                    <a
                      className="z-button secondary-color button-xl"
                      onClick={() => {
                        optimizely.track('click_main_action_button');
                        events.send('click_button__try_free_in_ready_start__workshop');
                      }}
                    >
                      {t('freeTrialBtn')}
                    </a>
                  </Link>
                </OptimizelyVariation>
                <OptimizelyVariation variation="variation_order_demo">
                  <Link href="https://forms.yandex.ru/cloud/631064d428c2a452963f4a1c/">
                    <a
                      className="z-button secondary-color button-xl"
                      onClick={() => {
                        optimizely.track('click_main_action_button');
                        events.send('click_button__demo_in_ready_start__workshop');
                      }}
                    >
                      {t('orderDemoBtn')}
                    </a>
                  </Link>
                </OptimizelyVariation>
                <OptimizelyVariation default>
                  <Link href="/access?tab=signup">
                    <a
                      className="z-button secondary-color button-xl"
                      onClick={() => {
                        optimizely.track('click_main_action_button');
                        events.send('click_button__try_free_in_ready_start__workshop');
                      }}
                    >
                      {t('freeTrialBtn')}
                    </a>
                  </Link>
                </OptimizelyVariation>
              </OptimizelyExperiment>
            </div>
            <div className={styles.payment_description}>
              <div>
                <ErrorOutlineIcon/>
                <span>{t('sections.readyStart.paymentInformation_1')}</span>
              </div>
              <div>
                <ErrorOutlineIcon/>
                <span>{t('sections.readyStart.paymentInformation_2')}</span>
              </div>

            </div>
          </div>
        </div>

        <div className={styles.image_wrapper}>
          <img src="/new_promo_page/images/start-bg.png" alt="ZEN.CAR start"/>
        </div>
      </div>
    </div>
  )
};

export default withOptimizely(ReadyStartSection);