import React, {
  useEffect,
  useState,
}                         from 'react';
import SwiperCore, {
  Navigation,
  Pagination,
}                         from 'swiper';
import {
  Swiper,
  SwiperSlide,
}                         from 'swiper/react';
import ReviewItem         from './components/ReviewItem';
import styles             from './reviews-section.module.scss';
import LinkWithArrow      from 'components/Site/NewLanding/UI/LinkWithArrow';
import { useTranslation } from 'next-i18next';

import ArrowBackIcon    from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import cns              from 'classnames';
import CloseIcon        from '@material-ui/icons/Close';
import { UserAgent }    from '@quentin-sommer/react-useragent';

SwiperCore.use([Navigation, Pagination]);

function ReviewsSection() {
  const {t} = useTranslation('landing');
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const ecsHandler = (evt) => {
      evt = evt || window.event;
      let isEscape = false;
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        setVisibleModal(false);
      }
    };

    document.addEventListener('keydown', ecsHandler);

    return () => {
      document.removeEventListener('keydown', ecsHandler);
    };
  }, []);

  useEffect(() => {
    const links = document.querySelectorAll('header a');

    const linkClickHandler = () => {
      setVisibleModal(false);
      document.body.classList.remove('disable-scroll');
    };

    links.forEach((elem) => {
      elem.addEventListener('click', linkClickHandler);
    });

    return () => {
      links.forEach((elem) => {
        elem.removeEventListener('click', linkClickHandler);
      });
    };
  }, []);

  const onOpenNavModalClick = (data) => {
    document.body.classList.add('disable-scroll');
    setModalData(data);
    setVisibleModal(true);
  };


  const onCloseNavModalClick = () => {
    document.body.classList.remove('disable-scroll');
    setVisibleModal(false);
  };

  return (
    <>
      <div className={styles.root} id={'reviews'}>
        <div className={cns(styles.modal_overlay, {visible: visibleModal})}/>
        <div className={styles.container}>
          <div className={styles.header_row}>
            <h3 className="section-title">
              {t('sections.reviews.title')}
            </h3>
            <div className={styles.controls}>
              <div className={styles.swiper_control}>
                <div className="button-prev" onClick={() => {
                  swiperInstance.slidePrev();
                }}>
                  <ArrowBackIcon/>
                </div>
              </div>
              <div className={styles.swiper_control}>
                <div className="button-next" onClick={() => {
                  swiperInstance.slideNext();
                }}>
                  <ArrowForwardIcon/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserAgent computer tablet>
          <div className={styles.swiper_container} style={{overflow: 'hidden'}}>
            <div className={styles.hide_container}>
              <Swiper
                spaceBetween={40}
                navigation={{
                  nextEl: '.button-next',
                  prevEl: '.button-prev',
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  1100: {
                    slidesPerView: 2,
                  },
                  1600: {
                    slidesPerView: 2.4,
                  },
                }}
                onSwiper={setSwiperInstance}
              >
                <SwiperSlide>
                  <div className={styles.review_container}>
                    <ReviewItem
                      imgLink={'/new_promo_page/images/ava3.png'}
                      name={t('sections.reviews.RuslanSadridinov.name')}
                      position={t('sections.reviews.RuslanSadridinov.position')}
                      reviewText={t('sections.reviews.RuslanSadridinov.review')}
                      benefits={[t('sections.reviews.RuslanSadridinov.benefit_1'), t('sections.reviews.RuslanSadridinov.benefit_2')]}
                      limit={t('sections.reviews.RuslanSadridinov.limit')}
                      video={() => onOpenNavModalClick('https://www.youtube.com/embed/vuPouDbgRxc')}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.review_container}>
                    <ReviewItem
                      imgLink={'/new_promo_page/images/ava1.png'}
                      name={t('sections.reviews.MaksimParigin.name')}
                      position={t('sections.reviews.MaksimParigin.position')}
                      reviewText={t('sections.reviews.MaksimParigin.review')}
                      benefits={[t('sections.reviews.MaksimParigin.benefit_1'), t('sections.reviews.MaksimParigin.benefit_2')]}
                      limit={t('sections.reviews.MaksimParigin.limit')}
                      video={() => onOpenNavModalClick('https://www.youtube.com/embed/HX63av0wmQE')}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.review_container}>
                    <ReviewItem
                      imgLink={'/new_promo_page/images/ava8.png'}
                      name={t('sections.reviews.IgorVladimirov.name')}
                      position={t('sections.reviews.IgorVladimirov.position')}
                      reviewText={t('sections.reviews.IgorVladimirov.review')}
                      benefits={[t('sections.reviews.IgorVladimirov.benefit_1'), t('sections.reviews.IgorVladimirov.benefit_2')]}
                      limit={t('sections.reviews.IgorVladimirov.limit')}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.review_container}>
                    <ReviewItem
                      imgLink={'/new_promo_page/images/ava2.png'}
                      name={t('sections.reviews.NataliaZavodskaya.name')}
                      position={t('sections.reviews.NataliaZavodskaya.position')}
                      reviewText={t('sections.reviews.NataliaZavodskaya.review')}
                      benefits={[t('sections.reviews.NataliaZavodskaya.benefit_1'), t('sections.reviews.NataliaZavodskaya.benefit_2')]}
                      limit={t('sections.reviews.NataliaZavodskaya.limit')}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.review_container}>
                    <ReviewItem
                      imgLink={'/new_promo_page/images/ava6.png'}
                      name={t('sections.reviews.RuslanZinoviev.name')}
                      position={t('sections.reviews.RuslanZinoviev.position')}
                      reviewText={t('sections.reviews.RuslanZinoviev.review')}
                      benefits={[t('sections.reviews.RuslanZinoviev.benefit_1'), t('sections.reviews.RuslanZinoviev.benefit_2')]}
                      limit={t('sections.reviews.RuslanZinoviev.limit')}
                    />

                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.review_container}>
                    <ReviewItem
                      imgLink={'/new_promo_page/images/ava4.png'}
                      name={t('sections.reviews.EmilZaripov.name')}
                      position={t('sections.reviews.EmilZaripov.position')}
                      reviewText={t('sections.reviews.EmilZaripov.review')}
                      benefits={[t('sections.reviews.EmilZaripov.benefit_1'), t('sections.reviews.EmilZaripov.benefit_2')]}
                      limit={t('sections.reviews.EmilZaripov.limit')}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </UserAgent>
        <UserAgent mobile>
          <Swiper
            spaceBetween={40}
            modules={[Pagination]}
            pagination={{clickable: true, type: 'bullets'}}
            onSwiper={setSwiperInstance}
          >
            <SwiperSlide>
              <div className={styles.review_container}>
                <ReviewItem
                  imgLink={'/new_promo_page/images/ava1.png'}
                  name={t('sections.reviews.MaksimParigin.name')}
                  position={t('sections.reviews.MaksimParigin.position')}
                  reviewText={t('sections.reviews.MaksimParigin.review')}
                  benefits={[t('sections.reviews.MaksimParigin.benefit_1'), t('sections.reviews.MaksimParigin.benefit_2')]}
                  limit={t('sections.reviews.MaksimParigin.limit')}
                  video={() => onOpenNavModalClick('https://www.youtube.com/embed/HX63av0wmQE')}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review_container}>
                <ReviewItem
                  imgLink={'/new_promo_page/images/ava3.png'}
                  name={t('sections.reviews.RuslanSadridinov.name')}
                  position={t('sections.reviews.RuslanSadridinov.position')}
                  reviewText={t('sections.reviews.RuslanSadridinov.review')}
                  benefits={[t('sections.reviews.RuslanSadridinov.benefit_1'), t('sections.reviews.RuslanSadridinov.benefit_2')]}
                  limit={t('sections.reviews.RuslanSadridinov.limit')}
                  video={() => onOpenNavModalClick('https://www.youtube.com/embed/vuPouDbgRxc')}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.review_container}>
                <ReviewItem
                  imgLink={'/new_promo_page/images/ava2.png'}
                  name={t('sections.reviews.NataliaZavodskaya.name')}
                  position={t('sections.reviews.NataliaZavodskaya.position')}
                  reviewText={t('sections.reviews.NataliaZavodskaya.review')}
                  benefits={[t('sections.reviews.NataliaZavodskaya.benefit_1'), t('sections.reviews.NataliaZavodskaya.benefit_2')]}
                  limit={t('sections.reviews.NataliaZavodskaya.limit')}
                />
              </div>
            </SwiperSlide>
          </Swiper>


        </UserAgent>

        <div className={styles.container}>
          <div className={styles.link_row}>
            <LinkWithArrow href="/reviews" text={t('sections.reviews.allReviews')}/>
          </div>
        </div>
      </div>
      <div style={{visibility: visibleModal ? 'visible' : 'hidden'}}
           className={cns('header-modal', styles.nav_modal, {visible: visibleModal})}>
        <div className={styles.modal_container}>
          <div className={styles.nav_header_row}>
            <div className={styles.nav_title}>Отзыв</div>
            <div
              className={styles.close_button}
              onClick={() => onCloseNavModalClick()}
            >
              <CloseIcon/>
            </div>
          </div>
          <iframe src={modalData} title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className={styles.video}
          ></iframe>
        </div>
      </div>
    </>

  );
}

export default ReviewsSection;