import {
  useState,
  useRef,
  memo,
  useEffect,
} from 'react';
import PropTypes         from 'prop-types';
import { withFormsy }    from 'formsy-react';
import { Form }          from 'formsy-semantic-ui-react';
import PhoneInput        from 'react-phone-input-2';
import { Label }         from 'semantic-ui-react';
import isFunction        from 'lodash/isFunction';
import isEmpty           from 'lodash/isEmpty';
import cns               from 'classnames';
import styles            from './ui-phone-input.module.scss';

import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';

const UIPhoneInput = ({
  isPristine, isValid, errorMessage, setValue, onChange,
  errorLabel, label, value, disabled, name, required, size,
  placeholder, newDesign, withIcon
}) => {
  const input = useRef(null);
  const [ isError, setError ] = useState(false);
  const instanceTimeoutRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(instanceTimeoutRef.current);
  }, [])

  const _isError = () => {
    return !isPristine && !isValid && !isEmpty(errorMessage);
  };

  const changeValue = (value) => {
    let _value = `+${value}`;

    if (!isEmpty(value)) {
      if (value[0] === '8') {
        _value = `+7${value.substr(1)}`;
      }

      if (value[0] === '9') {
        _value = `+79${value.substr(1)}`;
      }
    }

    setValue(_value);

    if (!isEmpty(value) && value.length === 1) {
      clearTimeout(instanceTimeoutRef.current);
      instanceTimeoutRef.current = setTimeout(() => {
        const numberInput = input.current.numberInputRef;
  
        if (numberInput.createTextRange) {
          const range = numberInput.createTextRange();
          range.move('character', 5);
    
        } else {
          numberInput.focus();
    
          if (numberInput.selectionStart !== undefined) {
            numberInput.setSelectionRange(5, 5);
          }
        }
      }, 0);
    }
    
    if (isFunction(onChange)) {
      onChange(null, { value: _value });
    }
  };

  const onBlur = () => {
    setError(_isError());

    if (value === '+') {
      setValue('');
    }
  };

  return (
    <div className={styles.component}>
      <div className={cns("field", {
        "new-design": newDesign,
        "no-label": !label,
        "required": required,
        "error": isError && !isValid
      })}>
        <If condition={label}>
          <label>{label} </label>
        </If>
        
        <PhoneInput
          ref={input}
          defaultCountry="ru"
          containerClass={cns("ui input icon left", {
            "error": isError && !isValid,
            [size]: !isEmpty(size)
          })}
          autoFormat
          placeholder={placeholder}
          value={value || ''}
          onBlur={onBlur}
          onChange={changeValue}
          disabled={disabled || false}
        />

        <If condition={withIcon}>
          <PhoneOutlinedIcon/>
        </If>

        <Form.Input
          className="hidden-input"
          type="hidden"
          name={name}
          value={value}
        />

        <If condition={errorLabel && isError && !isEmpty(errorMessage)}> 
          <Label color="red" pointing>{errorMessage}</Label>
        </If>
      </div>
    </div>
  )
};

UIPhoneInput.propTypes = {
  name: PropTypes.string.isRequired
};

UIPhoneInput.defaultProps = {
  placeholder: '7 (900) 000-00-00'
};

export default memo(withFormsy(UIPhoneInput));