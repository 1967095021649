import React, {
  useEffect,
  useState,
}                       from 'react';
import {
  Swiper,
  SwiperSlide,
}                           from 'swiper/react';
import {
  Pagination,
  Navigation,
}                           from 'swiper';
import ArrowBackIcon        from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon     from '@material-ui/icons/ArrowForward';
import { useProductEvents } from '../../../../../hooks';
import styles               from './MobileAppSection.module.scss';

const sectionInfo = [
  {
    title: 'Больше клиентов и записей на ремонт',
    description: 'Автоматические напоминания автовладельцам о замене масла, прохождении техосмотра и других необходимых работах',
  },
  {
    title: 'Ваш автосервис на карте приложения',
    description: 'Автовладельцы выбирают ближайший автосервис на карте, где отображены автосервисы воркшопа',
  },
  {
    title: 'Вы - избранные СТО',
    description: 'Автосервисы воркшопа отображаются как приоритетные при поиске и записи на ремонт',
  },
  {
    title: 'Запись сразу приходит в личный кабинет СТО',
    description: 'Экономьте время на записи на ремонт - удобная и понятная форма для каждого автовладельца',
  },
  {
    title: 'Вы не потеряете своих клиентов',
    description: 'Автовладельцы получают напоминания о ремонте за день до записи в автосервис',
  },
  {
    title: 'Вся история ремонтов под рукой',
    description: 'В личном кабинете СТО и в приложении автовладельца сохраняется вся история работ',
  },
  {
    title: 'Клиент возвращается снова',
    description: 'Автоматическое создание напоминаний вашему клиенту на основе рекомендаций из заказ-наряда',
  },
];

const MobileAppSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(6);

  const events = useProductEvents();

  useEffect(() => {
    if (window.innerWidth <= 560) {
      setSlidesPerView(1);
    } else if (window.innerWidth <= 900) {
      setSlidesPerView(2);
    } else if (window.innerWidth <= 1200) {
      setSlidesPerView(3);
    } else if (window.innerWidth <= 1700) {
      setSlidesPerView(4);
    }
  }, []);

  return (
    <section className={styles['mobile-app']}>
      <div className={styles['mobile-app__header-wrapper']}>
        <h2 className={`section-title ${styles['mobile-app__title']}`}>Мобильное приложение ZEN.CAR</h2>
        <div className={styles['mobile-app__subtitle']}>
          <span>Новое, активно развивающееся мобильное</span>
          <span>приложение ZEN.CAR для автовладельцев.</span>
          <span>Более 6000 установок за первый месяц</span>
        </div>
      </div>

      <div className={styles['mobile-app__slider']}>
        <div className={styles['mobile-app__slider-content']}>

          <div
            className={`${styles['mobile-app__btn-nav']} ${styles['mobile-app__btn-nav--mobile']} ${styles['mobile-app__btn-nav--prev']}`}
            onClick={() => {
              swiperInstance.slidePrev();
              events.send('click_button__left_in_mobile_app_adv_workshop');
            }}>
            <ArrowBackIcon/>
          </div>

          <Swiper
            slidesPerView={slidesPerView}
            slidesPerGroup={1}
            spaceBetween={80}
            loop={true}
            modules={[Pagination, Navigation]}
            centeredSlides={true}
            onActiveIndexChange={(swiperCore) => {
              setActiveSlide(swiperCore.realIndex);
            }}
            initialSlide={(() => {
              if (typeof window !== 'undefined' && window.innerWidth <= 560) {
                return 2;
              } else if (typeof window !== 'undefined' && window.innerWidth <= 900) {
                return 3;
              } else if (typeof window !== 'undefined' && window.innerWidth <= 1200) {
                return 4;
              } else if (typeof window !== 'undefined' && window.innerWidth <= 1700) {
                return 5;
              } else if (typeof window !== 'undefined' && window.innerWidth > 1700) {
                return 0;
              }
            })()}
            onSwiper={setSwiperInstance}
            className="mySwiper"
          >

            {Array.from({length: 7}, (_, i) => i + 1).map(slide => (
              <SwiperSlide key={slide}>
                <div className={`${styles['mobile-app__slide']} ${styles['mobile-app__slide--2x']}`}>
                  <img
                    className={slide === 1 || slide === 7 ? `${styles['mobile-app__img--large']} ${styles['mobile-app__img']}` : styles['mobile-app__img']}
                    src={`/images/mobileSlides/2x/${slide}.png`}
                    alt={'Slide-image'}
                  />
                </div>
                <div className={`${styles['mobile-app__slide']} ${styles['mobile-app__slide--1x']}`}>
                  <img
                    className={styles['mobile-app__img']}
                    src={`/images/mobileSlides/1x/${slide}.png`}
                    alt={'Slide-image'}
                  />
                </div>
              </SwiperSlide>
            ))}

          </Swiper>

          <div
            className={`${styles['mobile-app__btn-nav']} ${styles['mobile-app__btn-nav--mobile']} ${styles['mobile-app__btn-nav--next']}`}
            onClick={() => {
              swiperInstance.slideNext();
              events.send('click_button__right_in_mobile_app_adv_workshop');
            }}>
            <ArrowForwardIcon/>
          </div>

        </div>

        <div className={styles['mobile-app__navigation']}>
          <div className={`${styles['mobile-app__btn-nav']} ${styles['mobile-app__btn-nav--desktop']}`}
               onClick={() => {
                 swiperInstance.slidePrev();
                 events.send('click_button__left_in_mobile_app_adv_workshop');
               }}>
            <ArrowBackIcon/>
          </div>
          <div className={styles['mobile-app__description-wrapper']}>
            <h3 className={styles['mobile-app__heading-tertiary']}>{sectionInfo[activeSlide].title}</h3>
            <p className={styles['mobile-app__description']}>{sectionInfo[activeSlide].description}</p>
          </div>

          <div className={`${styles['mobile-app__btn-nav']} ${styles['mobile-app__btn-nav--desktop']}`}
               onClick={() => {
                 swiperInstance.slideNext();
                 events.send('click_button__right_in_mobile_app_adv_workshop');
               }}>
            <ArrowForwardIcon/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileAppSection;