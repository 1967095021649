import styles from './HistoryCompanyItem.module.scss';

const HistoryCompanyItem = ({events, year, imgPath}) => {
  return (
    <div className={styles.item}>
      <div>
        <img src={imgPath} className={styles.item__img} alt="Brand-logo" />
      </div>
      <div className={styles.item__card}>
        <span className={styles.item__year}>{year}</span>
        {events.map(event => (
          <div className={styles.item__content} key={event.description}>
            <h4 className={styles.item__heading}>{event.month}</h4>
            <p className={styles.item__label}>{event.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryCompanyItem;