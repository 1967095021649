import {
  useEffect,
  useRef,
} from 'react';
import styles from './section-main.module.scss';
import { useTranslation } from 'next-i18next';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import Link from 'next/link';
import { useProductEvents } from 'hooks';
import {
  OptimizelyExperiment,
  OptimizelyVariation,
  withOptimizely,
} from '@optimizely/react-sdk';

function SectionMain({ optimizely }) {
  const { t } = useTranslation('landing');
  const plates = useRef(null);
  const events = useProductEvents();
  useEffect(() => {
    let lastScrollPosition = 0;
    const speedVar = 0.9 * (630 / window.innerWidth); // При ширине 630 - 0.9// это лучший коэфицент

    let listener = (event) => {
      plates.current.style.left = `-${window.scrollY < window.innerWidth * 0.8 ? (window.scrollY - lastScrollPosition) * speedVar : `-${window.innerWidth * 0.6}`}px`;
    };
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.container}>

        <h1 className="section-title">
          {t('sections.main.title')}
        </h1>
        <p className={styles.text}>       {t('sections.main.text')}</p>
        <div className={styles.main_image}>
          <img src="new_promo_page/images/landing_main.png"></img>
        </div>

        <h2 className="section-subtitle">
          {t('sections.main.subtitle')}
        </h2>

        <div className={styles.plates} ref={plates}>
          <div className={styles.plate}>
            {t('sections.main.plate_1')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_2')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_3')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_4')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_5')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_6')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_7')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_8')}
          </div>
          <div className={styles.plate}>
            {t('sections.main.plate_9')}
          </div>
        </div>

        <OptimizelyExperiment experiment="zen_car_workshop_landing_try_demo_test">
          <OptimizelyVariation variation="variation_try_free">
            <Link href="/access?tab=signup">
              <div
                className={styles.button}
                onClick={() => {
                  optimizely.track('click_main_action_button');
                  events.send('click_button__try_free_on_main_page_after_main_view__workshop');
                }}
              >
                <span>Попробовать бесплатно</span>
              </div>
            </Link>
          </OptimizelyVariation>
          <OptimizelyVariation variation="variation_order_demo">
            <Link href="https://forms.yandex.ru/cloud/631064d428c2a452963f4a1c/">
              <div
                className={styles.button}
                onClick={() => {
                  optimizely.track('click_main_action_button');
                  events.send('click_button__demo_on_main_page_after_main_view__workshop');
                }}
              >
                <span>Запросить демо</span>
              </div>
            </Link>
          </OptimizelyVariation>
          <OptimizelyVariation default>
            <Link href="/access?tab=signup">
              <div
                className={styles.button}
                onClick={() => {
                  optimizely.track('click_main_action_button');
                  events.send('click_button__try_free_on_main_page_after_main_view__workshop');
                }}
              >
                <span>Попробовать бесплатно</span>
              </div>
            </Link>
          </OptimizelyVariation>
        </OptimizelyExperiment>

        <a className={styles.arrowUp} href={'#main'}>
          <ArrowRightAltIcon />
        </a>
        <div className={styles.chat}>
          <ForumOutlinedIcon />
        </div>

      </div>

    </div>
  );
}

export default withOptimizely(SectionMain);