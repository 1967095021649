import Link from '../Link'
import styles from './Attraction.module.scss';

const Attraction = ({label, linkLabel, href, onClick}) => {
  return (
    <div className={styles.attraction}>
      <span className={styles.attraction__heading}>{label}</span>
      <Link label={linkLabel} href={'/'} href={href} onClick={onClick}/>
    </div>
  );
};

export default Attraction;