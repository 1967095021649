import React                        from 'react';
import styles                       from './review-item.module.scss';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';

function ReviewItem({name, benefits, limit, reviewText, reviewFull, position, video, imgLink}) {

  return (
    <div className={styles.root}>
      <div className={styles.author}>
        <div className={styles.avatar}>
          <img alt="review author avatar" src={imgLink}/>
        </div>
        <div>
          <div className={styles.name}>
            {name}
          </div>
          <div className={styles.position}>
            {position}
          </div>
        </div>
      </div>

      <div className={styles.text}>
        {reviewFull}
        <If condition={benefits}>
          <div className={styles.benefits}>
            <span>Преимущества</span>
            <ul>
              <li>{benefits[0]}</li>
              <li>{benefits[1]}</li>
            </ul>
          </div>
        </If>
        <If condition={limit}>
          <div className={styles.limit}>
            <span>Недостатки</span>
            <div>
              {limit}
            </div>
          </div>
        </If>


        <If condition={reviewText}>
          <div className={styles.review}>
            <span>Отзыв</span>
            <div className={styles.text}>
              <If condition={video}>
                <div className={styles.video} onClick={video}>
                  <PlayCircleFilledOutlinedIcon/>
                </div>
              </If>

              <div className={styles.review_text}>
                {reviewText ? reviewText : reviewFull}
              </div>
            </div>
          </div>
          {/*<div className={styles.read}>
            <span>Читать весь отзыв</span>
          </div>*/}
        </If>

      </div>
    </div>
  )
}

export default ReviewItem;