import { useEffect }              from 'react';
import SitePage                   from 'components/Site/Page';
import { useTranslation }         from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import NewLandingView             from 'views/Site/NewLanding';
import { useProductEvents }       from 'hooks';

const SiteIndexPage = (props) => {
  const { t } = useTranslation('common');
  const title = t('og.title');
  const keywords = t('og.keywords');
  const events = useProductEvents();


  useEffect(() => {
    events.send('main_page_open_workshop');
  }, []);

  return (
    <SitePage
      {...props}
      title={title}
      keywords={keywords}
      isLanding={true}
    >
      <NewLandingView/>
    </SitePage>
  )
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...await serverSideTranslations(locale, ['common', 'landing', 'header'])
    }
  }
};

export default SiteIndexPage;