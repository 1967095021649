import { useState }       from 'react';
import styles             from './problems-section.module.scss';
import cns                from 'classnames';
import { useTranslation } from 'next-i18next';
import ArrowDownwardIcon  from '@material-ui/icons/ArrowDownward';


function ProblemsSection() {
  const {t} = useTranslation('landing');
  const [visibleAllProblems, setVisibleAllProblems] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2 className="section-title">
          {t('sections.problems.title')}
        </h2>
        <div className={styles.subtitle}>{t('sections.problems.subtitle')}</div>
        <div className={styles.problems}>
          <div className={styles.problem_wrapper}>
            <div className={styles.problem_row}>
              <div className={styles.problem}>
                <div className={styles.problem_title}>{t('sections.problems.problem_1_title')}</div>
                <span>{t('sections.problems.problem_1_description')}</span>
              </div>
              <div className={styles.decision}>
                <div className={styles.decision_title}>{t('sections.problems.decision_1_title')}</div>
                {t('sections.problems.decision_1_description')}
              </div>
            </div>
          </div>

          <div className={styles.problem_wrapper}>
            <div className={styles.problem_row}>
              <div className={styles.problem}>
                <div className={styles.problem_title}>{t('sections.problems.problem_2_title')}</div>
                <span>{t('sections.problems.problem_2_description')}</span>
              </div>
              <div className={styles.decision}>
                <div className={styles.decision_title}>{t('sections.problems.decision_2_title')}</div>
                <span>{t('sections.problems.decision_2_description')}</span>
              </div>
            </div>
          </div>

          <div className={styles.problem_wrapper}>
            <div className={styles.problem_row}>
              <div className={styles.problem}>
                <div className={styles.problem_title}>{t('sections.problems.problem_3_title')}</div>
                <span>{t('sections.problems.problem_3_description')}</span>
              </div>
              <div className={styles.decision}>
                <div className={styles.decision_title}>{t('sections.problems.decision_3_title')}</div>
                {t('sections.problems.decision_3_description')}
              </div>
            </div>
          </div>

          <div className={cns(styles.additional_problems, {show: visibleAllProblems})}>

            <div className={styles.problem_wrapper}>
              <div className={styles.problem_row}>
                <div className={styles.problem}>
                  <div className={styles.problem_title}>{t('sections.problems.problem_4_title')}</div>
                  <span>{t('sections.problems.problem_4_description')}</span>
                </div>
                <div className={styles.decision}>
                  <div className={styles.decision_title}>{t('sections.problems.decision_4_title')}</div>
                  {t('sections.problems.decision_4_description')}
                </div>
              </div>
            </div>

            <div className={styles.problem_wrapper}>
              <div className={styles.problem_row}>
                <div className={styles.problem}>
                  <div className={styles.problem_title}>{t('sections.problems.problem_5_title')}</div>
                  <span>{t('sections.problems.problem_5_description')}</span>
                </div>
                <div className={styles.decision}>
                  <div className={styles.decision_title}>{t('sections.problems.decision_5_title')}</div>
                  {t('sections.problems.decision_5_description')}
                </div>
              </div>
            </div>

            <div className={styles.problem_wrapper}>
              <div className={styles.problem_row}>
                <div className={styles.problem}>
                  <div className={styles.problem_title}>{t('sections.problems.problem_6_title')}</div>
                  <span>{t('sections.problems.problem_6_description')}</span>
                </div>
                <div className={styles.decision}>
                  <div className={styles.decision_title}>{t('sections.problems.decision_6_title')}</div>
                  {t('sections.problems.decision_6_description')}
                </div>
              </div>
            </div>

            <div className={styles.problem_wrapper}>
              <div className={styles.problem_row}>
                <div className={styles.problem}>
                  <div className={styles.problem_title}>{t('sections.problems.problem_7_title')}</div>
                  <span>{t('sections.problems.problem_7_description')}</span>
                </div>
                <div className={styles.decision}>
                  <div className={styles.decision_title}>{t('sections.problems.decision_7_title')}</div>
                  {t('sections.problems.decision_7_description')}
                </div>
              </div>
            </div>
          </div>

          <If condition={!visibleAllProblems}>
            <div
              className={styles.toggle_visibility}
              onClick={() => {setVisibleAllProblems(true)}}
            >
              <span className={styles.toggle_text}>{t('sections.problems.moreLink')}</span>
              <ArrowDownwardIcon/>

            </div>
          </If>

        </div>
      </div>
    </div>
  )
}

export default ProblemsSection;