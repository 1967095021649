import { useState }          from 'react';
import styles                from './view-tabs.module.scss';
import { useTranslation }    from 'next-i18next';
import { UserAgent }         from '@quentin-sommer/react-useragent';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import { Pagination }        from 'swiper';
import {
  Swiper,
  SwiperSlide,
}                            from 'swiper/react';
import ArrowForwardIcon      from '@material-ui/icons/ArrowForward';
import Link                  from 'next/link';
import { useRouter }         from 'next/router';

// SwiperCore.use([Pagination]);

function ForWhomSection() {
  const {t} = useTranslation('landing');
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [video, setVideo] = useState('repairs');
  const router = useRouter();
  return (
    <div className={styles.root} id={'features'}>
      <div className={styles.container}>
        <div className="section-title">
          {t('sections.viewTabs.title')}
        </div>
        <UserAgent computer>
          <div className={styles.content}>
            <div className={styles.oportunities}>
              <div className={styles.opportunity} onClick={() => setVideo('repairs')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'repairs' ? '#ff204e' : ''}}/>
                <span style={{color: video === 'repairs' ? '#ff204e' : ''}}>{t('sections.viewTabs.oportunity_1')}</span>
              </div>
              <div className={styles.opportunity} onClick={() => setVideo('booking')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'booking' ? '#ff204e' : ''}}/>
                <span style={{color: video === 'booking' ? '#ff204e' : ''}}>{t('sections.viewTabs.oportunity_2')}</span>
              </div>
              <div className={styles.opportunity} onClick={() => setVideo('time_norms')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'time_norms' ? '#ff204e' : ''}}/>
                <span
                  style={{color: video === 'time_norms' ? '#ff204e' : ''}}>{t('sections.viewTabs.oportunity_3')}</span>
              </div>
              <div className={styles.opportunity} onClick={() => setVideo('store')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'store' ? '#ff204e' : ''}}/>
                <span style={{color: video === 'store' ? '#ff204e' : ''}}>{t('sections.viewTabs.oportunity_4')}</span>
              </div>
              {/*<div className={styles.opportunity} onClick={() => setVideo('5')}>*/}
              {/*  <PlayArrowOutlinedIcon/>*/}
              {/*  <span>{t('sections.viewTabs.oportunity_5')}</span>*/}
              {/*</div>*/}
              <div className={styles.opportunity} onClick={() => setVideo('analytics_5')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'analytics_5' ? '#ff204e' : ''}}/>
                <span
                  style={{color: video === 'analytics_5' ? '#ff204e' : ''}}>{t('sections.viewTabs.oportunity_6')}</span>
              </div>
              <Link href={`/features?tab=repair${!!router.query.promo ? `&promo=${router.query.promo}` : ''}`}>
                <div className={styles.all_features_link}>
                  <span style={{marginRight: '10px'}}>Все возможности</span>
                  <ArrowForwardIcon/>
                </div>
              </Link>

            </div>
            <div className={styles.video}>
              <video autoPlay muted loop src={`/new_promo_page/videos/${video}.mp4`}>
                {/*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                {/*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
              </video>
            </div>
          </div>
        </UserAgent>

        <UserAgent mobile tablet>
          <Swiper
            spaceBetween={40}
            modules={[Pagination]}
            pagination={{clickable: true, type: 'bullets'}}
            onSwiper={setSwiperInstance}
          >
            <SwiperSlide>
              <span>Работа с заказ-нарядами</span>
              <div className="video-mobile">
                {/*<video autoPlay muted loop src={`/new_promo_page/videos/repairs.mp4`}>*/}
                {/*  /!*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*!/*/}
                {/*  /!*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*!/*/}
                {/*</video>*/}
                <img src={'/images/sections/opportunities-section/workWithRepair.png'} alt={'repair-image'}/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <span>Запись клиента и загрузка постов</span>
              <div className="video-mobile">
                {/*<video autoPlay muted loop src={`/new_promo_page/videos/booking.mp4`}>*/}
                  {/*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                  {/*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
                {/*</video>*/}
                <img src={'/images/sections/opportunities-section/booking.png'} alt={'booking-image'}/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <span>Нормы времени, расчет стоимости работы</span>
              <div className="video-mobile">
                {/*<video autoPlay muted loop src={`/new_promo_page/videos/time_norms.mp4`}>*/}
                {/*  /!*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*!/*/}
                {/*  /!*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*!/*/}
                {/*</video>*/}
                <img src={'/images/sections/opportunities-section/normsOfTime.png'} alt={'time-image'}/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <span>ведение склада, учет товаров и запчастей</span>
              <div className="video-mobile">
                {/*<video autoPlay muted loop src={`/new_promo_page/videos/store.mp4`}>*/}
                  {/*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                  {/*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
                {/*</video>*/}
                <img src={'/images/sections/opportunities-section/stocks.png'} alt={'stocks-image'}/>
              </div>
            </SwiperSlide>
            {/*<SwiperSlide>*/}
            {/*  <span>SMS-оповещение клиентов</span>*/}
            {/*  <div className="video-mobile">*/}

            {/*  </div>*/}
            {/*</SwiperSlide>*/}
            <SwiperSlide>
              <span>Анализ эффективности сто</span>
              <div className="video-mobile">
                {/*<video autoPlay muted loop src={`/new_promo_page/videos/analytics_5.mp4`}>*/}
                  {/*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                  {/*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
                {/*</video>*/}
                <img src={'/images/sections/opportunities-section/analysis.png'} alt={'analysis-image'}/>
              </div>
            </SwiperSlide>
          </Swiper>
        </UserAgent>
      </div>

    </div>
  )
}

export default ForWhomSection;
