import Link from 'next/link'
import styles from './PartnersSectionCard.module.scss';

const PartnersSectionCard = ({logoPath, heading, description, href}) => {
  return (
    <div className={styles.card}>
      <div className={`${styles.card__side} ${styles['card__side--front']}`}>
        <div className={styles['card__img-wrapper']}>
          <img className={styles.card__img} src={logoPath} alt={'logo'}/>
        </div>
      </div>
      <div className={`${styles.card__side} ${styles['card__side--back']}`}>
        {/*<p>Back side!</p>*/}
        <div className={styles['card__back-content']}>
          <h4 className={styles['card__heading-back-side']}>{heading}</h4>
          <p className={styles['card__description-back-side']}>{description}</p>
        </div>
        <Link href={href}>
          <a target="_blank" className={styles.card__link}>Подробнее о партнере</a>
        </Link>
      </div>
    </div>
  );
};

export default PartnersSectionCard;