import { useState }       from 'react';
import {
  Swiper,
  SwiperSlide,
}                         from 'swiper/react';
import styles             from './promo-text-section.module.scss';
import { useTranslation } from 'next-i18next';

import DoneAllOutlinedIcon       from '@material-ui/icons/DoneAllOutlined';
import CloudDoneOutlinedIcon     from '@material-ui/icons/CloudDoneOutlined';
import CachedOutlinedIcon        from '@material-ui/icons/CachedOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import TimerOutlinedIcon         from '@material-ui/icons/TimerOutlined';
import ThumbUpAltOutlinedIcon    from '@material-ui/icons/ThumbUpAltOutlined';
import Link                      from 'next/link';
import { UserAgent }             from '@quentin-sommer/react-useragent';
import { Pagination }            from 'swiper';
import { useProductEvents }      from '../../../../../hooks';

function ProblemsSection() {
  const {t} = useTranslation('landing');
  const [swiperInstance, setSwiperInstance] = useState(null);
  const events = useProductEvents();

  return (
    <>
      <div className={styles.root} id={'advantages'}>
        <div className={styles.container}>
          <div className="section-title">
            {t('sections.promoText.title')}
          </div>
          <UserAgent computer tablet>
            <div className={styles.benefits}>
              <div className={styles.benefit}>
                <div className={styles.benefit_icon}>
                  <DoneAllOutlinedIcon/>
                </div>
                <div className={styles.benefit_text}>
                  {t('sections.promoText.li_1')}
                </div>
              </div>
              <div className={styles.benefit}>
                <div className={styles.benefit_icon}>
                  <CloudDoneOutlinedIcon/>
                </div>
                <div className={styles.benefit_text}>
                  {t('sections.promoText.li_2')}
                </div>
              </div>
              <div className={styles.benefit}>
                <div className={styles.benefit_icon}>
                  <CachedOutlinedIcon/>
                </div>
                <div className={styles.benefit_text}>
                  {t('sections.promoText.li_3')}
                </div>
              </div>
              <div className={styles.benefit}>
                <div className={styles.benefit_icon}>
                  <PeopleOutlineOutlinedIcon/>
                </div>
                <div className={styles.benefit_text}>
                  {t('sections.promoText.li_4')}
                </div>
              </div>
              <div className={styles.benefit}>
                <div className={styles.benefit_icon}>
                  <TimerOutlinedIcon/>
                </div>
                <div className={styles.benefit_text}>
                  {t('sections.promoText.li_5')}
                </div>
              </div>
              <div className={styles.benefit}>
                <div className={styles.benefit_icon}>
                  <ThumbUpAltOutlinedIcon/>
                </div>
                <div className={styles.benefit_text}>
                  {t('sections.promoText.li_6')}
                </div>
              </div>
            </div>
          </UserAgent>
          <UserAgent mobile>
            <Swiper
              spaceBetween={40}
              modules={[Pagination]}
              pagination={{clickable: true, type: 'bullets'}}
              onSwiper={setSwiperInstance}
            >
              <SwiperSlide>
                <div className={styles.benefits}>
                  <div className={styles.benefit}>
                    <div className={styles.benefit_icon}>
                      <DoneAllOutlinedIcon/>
                    </div>
                    <div className={styles.benefit_text}>
                      {t('sections.promoText.li_1')}
                    </div>
                  </div>
                  <div className={styles.benefit}>
                    <div className={styles.benefit_icon}>
                      <CloudDoneOutlinedIcon/>
                    </div>
                    <div className={styles.benefit_text}>
                      {t('sections.promoText.li_2')}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.benefits}>
                  <div className={styles.benefit}>
                    <div className={styles.benefit_icon}>
                      <CachedOutlinedIcon/>
                    </div>
                    <div className={styles.benefit_text}>
                      {t('sections.promoText.li_3')}
                    </div>
                  </div>
                  <div className={styles.benefit}>
                    <div className={styles.benefit_icon}>
                      <PeopleOutlineOutlinedIcon/>
                    </div>
                    <div className={styles.benefit_text}>
                      {t('sections.promoText.li_4')}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.benefits}>
                  <div className={styles.benefit}>
                    <div className={styles.benefit_icon}>
                      <TimerOutlinedIcon/>
                    </div>
                    <div className={styles.benefit_text}>
                      {t('sections.promoText.li_5')}
                    </div>
                  </div>
                  <div className={styles.benefit}>
                    <div className={styles.benefit_icon}>
                      <ThumbUpAltOutlinedIcon/>
                    </div>
                    <div className={styles.benefit_text}>
                      {t('sections.promoText.li_6')}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </UserAgent>
          <div className={styles.demo}>
            <Link href={'/#tarrifs'}>
              <div className={styles.demo_button}
                   onClick={() => events.send('click_button__ready_start_in_promo_section__workshop')}>
                <span>Готовы начать?</span>
              </div>
            </Link>

            <div className={styles.demo_text}>
              <span>{t('sections.forwhom.demo_text_1')}</span>
              <span>{t('sections.forwhom.demo_text_2')}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProblemsSection;