import styles             from './forwhom-section.module.scss';
import { useTranslation } from 'next-i18next';
import Link               from 'next/link';

import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PeopleAltOutlinedIcon      from '@material-ui/icons/PeopleAltOutlined';
import TimerOutlinedIcon          from '@material-ui/icons/TimerOutlined';
import SubtitlesOutlinedIcon      from '@material-ui/icons/SubtitlesOutlined';
import AllInboxOutlinedIcon       from '@material-ui/icons/AllInboxOutlined';
import { useState }               from 'react';
import { useProductEvents }       from 'hooks';
import {
  Swiper,
  SwiperSlide,
}                                 from 'swiper/react';
import { UserAgent }              from '@quentin-sommer/react-useragent';
import { Pagination }             from 'swiper';
import {
  OptimizelyExperiment,
  OptimizelyVariation,
  withOptimizely,
}                                 from '@optimizely/react-sdk';

function ForWhomSection({optimizely}) {
  const {t} = useTranslation('landing');
  const events = useProductEvents();
  const [swiperInstance, setSwiperInstance] = useState(null);

  return (
    <div className={styles.root} id={'forwhom'}>
      <div className={styles.container}>
        <div className="section-title">
          {t('sections.forwhom.title')}
        </div>
        <div className={styles.forwhom}>
          <UserAgent computer tablet>
            <div className={styles.forwhom_wrapper}>
              <div className={styles.case}>
                <div className={styles.case_icon}>
                  <MonetizationOnOutlinedIcon/>
                </div>
                <div className={styles.case_name}>
                  <span>{t('sections.forwhom.case_1.name')}</span>
                </div>
                <div className={styles.case_roles}>
                  <div className={styles.case_role}>
                    <span>{t('sections.forwhom.roles.owner')}</span>
                  </div>
                  <div className={styles.case_role}>
                    <span>{t('sections.forwhom.roles.ceo')}</span>
                  </div>
                </div>
                <div className={styles.case_solutions}>
                  <ul>
                    <li>{t('sections.forwhom.case_1.solutions.1')}</li>
                    <li>{t('sections.forwhom.case_1.solutions.2')}</li>
                    <li>{t('sections.forwhom.case_1.solutions.3')}</li>
                  </ul>
                </div>
              </div>
              <div className={styles.case}>
                <div className={styles.case_icon}>
                  <PeopleAltOutlinedIcon/>
                </div>
                <div className={styles.case_name}>
                  <span>{t('sections.forwhom.case_2.name')}</span>
                </div>
                <div className={styles.case_roles}>
                  <div className={styles.case_role}>
                    <span>{t('sections.forwhom.roles.marketing')}</span>
                  </div>
                </div>
                <div className={styles.case_solutions}>
                  <ul>
                    <li>{t('sections.forwhom.case_2.solutions.1')}</li>
                    <li>{t('sections.forwhom.case_2.solutions.2')}</li>
                    <li>{t('sections.forwhom.case_2.solutions.3')}</li>
                  </ul>
                </div>
              </div>
              <div className={styles.case}>
                <div className={styles.case_icon}>
                  <TimerOutlinedIcon/>
                </div>
                <div className={styles.case_name}>
                  <span>{t('sections.forwhom.case_3.name')}</span>
                </div>
                <div className={styles.case_roles}>
                  <div className={styles.case_role}>
                    <span>{t('sections.forwhom.roles.master')}</span>
                  </div>
                </div>
                <div className={styles.case_solutions}>
                  <ul>
                    <li>{t('sections.forwhom.case_3.solutions.1')}</li>
                    <li>{t('sections.forwhom.case_3.solutions.2')}</li>
                    <li>{t('sections.forwhom.case_3.solutions.3')}</li>
                    <li>{t('sections.forwhom.case_3.solutions.4')}</li>
                  </ul>
                </div>
              </div>
              <div className={styles.case}>
                <div className={styles.case_icon}>
                  <SubtitlesOutlinedIcon/>
                </div>
                <div className={styles.case_name}>
                  <span>{t('sections.forwhom.case_4.name')}</span>
                </div>
                <div className={styles.case_roles}>
                  <div className={styles.case_role}>
                    <span>{t('sections.forwhom.roles.mech')}</span>
                  </div>
                </div>
                <div className={styles.case_solutions}>
                  <ul>
                    <li>{t('sections.forwhom.case_4.solutions.1')}</li>
                    <li>{t('sections.forwhom.case_4.solutions.2')}</li>
                    <li>{t('sections.forwhom.case_4.solutions.3')}</li>
                    <li>{t('sections.forwhom.case_4.solutions.4')}</li>
                  </ul>
                </div>
              </div>
              <div className={styles.case}>
                <div className={styles.case_icon}>
                  <AllInboxOutlinedIcon/>
                </div>
                <div className={styles.case_name}>
                  <span>{t('sections.forwhom.case_5.name')}</span>
                </div>
                <div className={styles.case_roles}>
                  <div className={styles.case_role}>
                    <span>{t('sections.forwhom.roles.ozu')}</span>
                  </div>
                </div>
                <div className={styles.case_solutions}>
                  <ul>
                    <li>{t('sections.forwhom.case_5.solutions.1')}</li>
                    <li>{t('sections.forwhom.case_5.solutions.2')}</li>
                    <li>{t('sections.forwhom.case_5.solutions.3')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </UserAgent>
          <UserAgent mobile>
            <Swiper
              spaceBetween={80}
              modules={[Pagination]}
              pagination={{clickable: true, type: 'bullets'}}
              onSwiper={setSwiperInstance}
            >
              <SwiperSlide>
                <div className={styles.forwhom_wrapper}>
                  <div className={styles.case}>
                    <div className={styles.case_icon}>
                      <MonetizationOnOutlinedIcon/>
                    </div>
                    <div className={styles.case_name}>
                      <span>{t('sections.forwhom.case_1.name')}</span>
                    </div>
                    <div className={styles.case_roles}>
                      <div className={styles.case_role}>
                        <span>{t('sections.forwhom.roles.owner')}</span>
                      </div>
                      <div className={styles.case_role}>
                        <span>{t('sections.forwhom.roles.ceo')}</span>
                      </div>
                    </div>
                    <div className={styles.case_solutions}>
                      <ul>
                        <li>{t('sections.forwhom.case_1.solutions.1')}</li>
                        <li>{t('sections.forwhom.case_1.solutions.2')}</li>
                        <li>{t('sections.forwhom.case_1.solutions.3')}</li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.case}>
                    <div className={styles.case_icon}>
                      <PeopleAltOutlinedIcon/>
                    </div>
                    <div className={styles.case_name}>
                      <span>{t('sections.forwhom.case_2.name')}</span>
                    </div>
                    <div className={styles.case_roles}>
                      <div className={styles.case_role}>
                        <span>{t('sections.forwhom.roles.marketing')}</span>
                      </div>
                    </div>
                    <div className={styles.case_solutions}>
                      <ul>
                        <li>{t('sections.forwhom.case_2.solutions.1')}</li>
                        <li>{t('sections.forwhom.case_2.solutions.2')}</li>
                        <li>{t('sections.forwhom.case_2.solutions.3')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.forwhom_wrapper}>
                  <div className={styles.case}>
                    <div className={styles.case_icon}>
                      <TimerOutlinedIcon/>
                    </div>
                    <div className={styles.case_name}>
                      <span>{t('sections.forwhom.case_3.name')}</span>
                    </div>
                    <div className={styles.case_roles}>
                      <div className={styles.case_role}>
                        <span>{t('sections.forwhom.roles.master')}</span>
                      </div>
                    </div>
                    <div className={styles.case_solutions}>
                      <ul>
                        <li>{t('sections.forwhom.case_3.solutions.1')}</li>
                        <li>{t('sections.forwhom.case_3.solutions.2')}</li>
                        <li>{t('sections.forwhom.case_3.solutions.3')}</li>
                        <li>{t('sections.forwhom.case_3.solutions.4')}</li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.case}>
                    <div className={styles.case_icon}>
                      <SubtitlesOutlinedIcon/>
                    </div>
                    <div className={styles.case_name}>
                      <span>{t('sections.forwhom.case_4.name')}</span>
                    </div>
                    <div className={styles.case_roles}>
                      <div className={styles.case_role}>
                        <span>{t('sections.forwhom.roles.mech')}</span>
                      </div>
                    </div>
                    <div className={styles.case_solutions}>
                      <ul>
                        <li>{t('sections.forwhom.case_4.solutions.1')}</li>
                        <li>{t('sections.forwhom.case_4.solutions.2')}</li>
                        <li>{t('sections.forwhom.case_4.solutions.3')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.forwhom_wrapper}>
                  <div className={styles.case}>
                    <div className={styles.case_icon}>
                      <AllInboxOutlinedIcon/>
                    </div>
                    <div className={styles.case_name}>
                      <span>{t('sections.forwhom.case_5.name')}</span>
                    </div>
                    <div className={styles.case_roles}>
                      <div className={styles.case_role}>
                        <span>{t('sections.forwhom.roles.ozu')}</span>
                      </div>
                    </div>
                    <div className={styles.case_solutions}>
                      <ul>
                        <li>{t('sections.forwhom.case_5.solutions.1')}</li>
                        <li>{t('sections.forwhom.case_5.solutions.2')}</li>
                        <li>{t('sections.forwhom.case_5.solutions.3')}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </SwiperSlide>
            </Swiper>
          </UserAgent>
        </div>
        <div className={styles.demo}>
          <OptimizelyExperiment experiment="zen_car_workshop_landing_try_demo_test">
            <OptimizelyVariation variation="variation_try_free">
              <Link href="/access?tab=signup">
                <a
                  className={styles.demo_button}
                  onClick={() => {
                    optimizely.track('click_main_action_button');
                    events.send('click_button__try_free_in_for_whom__workshop');
                  }}
                >
                  <span>Попробовать бесплатно</span>
                </a>
              </Link>
            </OptimizelyVariation>
            <OptimizelyVariation variation="variation_order_demo">
              <Link href="https://forms.yandex.ru/cloud/631064d428c2a452963f4a1c/">
                <a
                  className={styles.demo_button}
                  onClick={() => {
                    optimizely.track('click_main_action_button');
                    events.send('click_button__demo_in_for_whom__workshop');
                  }}
                >
                  <span>Запросить демо</span>
                </a>
              </Link>
            </OptimizelyVariation>
            <OptimizelyVariation default>
              <Link href="/access?tab=signup">
                <a
                  className={styles.demo_button}
                  onClick={() => {
                    optimizely.track('click_main_action_button');
                    events.send('click_button__try_free_in_for_whom__workshop');
                  }}
                >
                  <span>Попробовать бесплатно</span>
                </a>
              </Link>
            </OptimizelyVariation>
          </OptimizelyExperiment>
          <div className={styles.demo_text}>
            <span>{t('sections.forwhom.demo_text_1')}</span> <span>{t('sections.forwhom.demo_text_2')}</span>
          </div>
        </div>
      </div>

    </div>
  )
}

export default withOptimizely(ForWhomSection);
