import HistoryCompanyItem from './HistoryCompanyItem';
import styles             from './HistoryCompany.module.scss';

const historyList = [
  {
    events: [
      {
        month: 'апрель',
        description: 'Основание компании ZEN.CAR, формирование команды',
      },
      {
        month: 'Сентябрь',
        description: 'Запуск платформы, объединяющей автосервисы и автовладельцев',
      },
    ],
    year: '2016',
    img: '/images/landing/history/history-1.png'
  },
  {
    events: [
      {
        month: 'март',
        description: 'Совместный запуск нашей платформы по подбору автосервисов на портале Автокод',
      },
      {
        month: 'октябрь',
        description: 'Интеграция с платежной системой Paylate. Услуги по ремонту автомобилей в рассрочку',
      },
    ],
    year: '2017',
    img: '/images/landing/history/history-2.png'
  },
  {
    events: [
      {
        month: 'апрель',
        description: 'Интеграция с департаментом транспорта Москвы и сервисом Тройка. Начисление баллов  через zen.car ',
      },
    ],
    year: '2018',
    img: '/images/landing/history/history-3.png'
  },
  {
    events: [
      {
        month: 'январь',
        description: 'Размещение на портале Госуслуг Республики Татарстан. Запись на ремонт и выбор лучшей цены',
      },
      {
        month: 'март',
        description: 'Запуск учетной системы для СТО - платформа Shell Garage',
      },
    ],
    year: '2019',
    img: '/images/landing/history/history-4.png'
  },
  {
    events: [
      {
        month: 'май',
        description: 'Интеграция с системой Клуб Чемпионов ПАО “Татнефть“ ',
      },
      {
        month: 'октябрь',
        description: 'Запуск цифровой платформы для СТО workshop.zen.car.',
      },
    ],
    year: '2020',
    img: '/images/landing/history/history-5.png'
  },
  {
    events: [
      {
        month: 'март',
        description: 'Стали резидентами особой экономической зоны “Иннополис“',
      },
      {
        month: 'август',
        description: 'Вошли в реестр отечественного программного обеспечения',
      },
      {
        month: 'ноябрь',
        description: 'Вошли в топ 10 лучших российских стартапов',
      },
    ],
    year: '2021',
    img: '/images/landing/history/history-6.png'
  },
  {
    events: [
      {
        month: 'февраль',
        description: 'Выпуск мобильного приложения для записи на ремонт и контролю показателей автомобиля',
      },
    ],
    year: '2022',
    img: '/images/landing/history/history-7.png'
  },
];

const HistoryCompany = () => {
  return (
      <section className={styles.history}>
        <div className={styles.history__container}>
          <h3 className={`section-title ${styles.history__heading}`}>История развития ZEN.CAR</h3>
          <div className={styles.history__content}>
            {historyList.map(history => (
              <HistoryCompanyItem key={history.year} events={history.events} year={history.year} imgPath={history.img}/>
            ))}
          </div>
        </div>
      </section>
  );
};

export default HistoryCompany;