import PartnersSectionCard from './PartnersSectionCard';
import styles from './PartnersSection.module.scss';
import Attraction from '../../UI/Attraction';
import { useProductEvents } from '../../../../../hooks';

const cards = [
  {
    logo: '/images/svg/partners/car-taxi-logo.svg',
    heading: 'CarTaxi - удобный сервис для заказа эвакуатора',
    description: 'Для клиентов workshop.zen.car -5% на каждый вызов эвакуатора.',
    link: "https://cartaxi.io"
  },
  {
    logo: '/images/svg/partners/gosuslugi-logo.svg',
    heading: 'Госуслуги - единый портал государственных услуг',
    description: 'Официальный партнер Госуслуг Татарстана по ремонту и обслуживанию автомобиля.',
    link: "https://uslugi.tatarstan.ru"
  },
  {
    logo: '/images/svg/partners/autocode-logo.svg',
    heading: 'Автокод - портал Правительства Москвы',
    description: 'Запись на обслуживание и ремонт автомобиля, сравнение предложений от автосервисов.',
    link: "https://avtokod.mos.ru/Pages/Autoservices.aspx"
  },
  {
    logo: '/images/svg/partners/dot-bank-logo.svg',
    heading: 'Точка - банк для предпринимателей и предприятий',
    description: 'Регистрация бизнеса, подключение бухгалтерии.',
    link: "https://tochka.com/?utm_source=workshop_zen_car&utm_medium=tochkareklamapartner"
  },
  {
    logo: '/images/svg/partners/calltouch-logo.svg',
    heading: 'Calltouch - система сквозной аналитики, коллтрекинга и управления рекламой',
    description: 'Для клиентов workshop.zen.car кешбэк от 15% на услуги Calltouch.',
    link: "https://my.calltouch.ru/user/registration/?ref=workshop.zen.car"
  },
  {
    logo: '/images/svg/partners/reg-ru-logo.svg',
    heading: 'РЕГ.РУ – регистратор доменных имён и хостинг-провайдеров',
    description: 'Для клиентов workshop.zen.car кешбэк на услуги создания сайта, регистрацию доменов.',
    link: "https://www.reg.ru/domain/new/?rlink=reflink-9435317"
  },
  {
    logo: '/images/svg/partners/innopolis-logo.svg',
    heading: 'Innopolis - город высоких технологий',
    description: 'ZEN.CAR – резиденты особой экономической зоны Иннополис.',
    link: "https://sezinnopolis.ru"
  },
  {
    logo: '/images/svg/partners/tatneft-logo.svg',
    heading: 'Татнефть - одна из крупнейших российских нефтяных компаний',
    description: 'Официальный партнер сети автозаправочных станций Татнефть.',
    link: "https://www.tatneft.ru"
  },
  {
    logo: '/images/svg/partners/napto-logo.png',
    heading: 'Национальная ассоциация предприятий технического обслуживания транспортных средств',
    description: '',
    link: "http://www.napto.ru/"
  },
  {
    logo: '/new_promo_page/partners/groupauto.png',
    heading: 'Академия GROUPAUTO – инструменты для эффективной организации работы автосервиса.',
    description: '',
    link: "https://academiyacto.ru/"
  },

];

const PartnersSection = () => {
  const events = useProductEvents();

  return (
    <section className={styles.partners}>
      <div className={styles.partners__wrapper}>
        <h3 className={`section-title ${styles.partners__heading}`}>Наши партнеры</h3>
        <div className={styles['partners__cards-wrapper']}>
          {cards.map(card => (
            <PartnersSectionCard
              key={card.heading}
              logoPath={card.logo}
              heading={card.heading}
              description={card.description}
              href={card.link}
            />
          ))}
        </div>
        <Attraction label="Хотите стать партнером?" linkLabel="Оставить заявку" href="https://forms.yandex.ru/cloud/625fdee66160b65390332bd0/" onClick={() => events.sendTo(['amplitude'], 'click_button__submit_application_in_partners__workshop')} />
      </div>
    </section>
  );
};

export default PartnersSection;