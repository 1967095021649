import Link from 'next/link';
import styles from './Link.module.scss';

const LinkRouter = ({href, label, onClick}) => {
  return (
    <Link href={href}>
      <a target="_blank" className={styles.link} onClick={onClick}>{label}</a>
    </Link>
  );
};

export default LinkRouter;