import styles             from './benefits-in-numbers.module.scss';
import { useTranslation } from 'next-i18next';

function BenefitsInNumbers() {
  const {t} = useTranslation('landing');

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h3 className="section-title">
          {t('sections.numbers.title')}
        </h3>
        <div className={styles.subtitle}>{t('sections.numbers.subtitle')}</div>
        <div className={styles.numbers}>
          <div className={styles.number_block}>
            <div className={styles.number}>
              <div className={styles.old}>3</div>
              <div className={styles.new}>1</div>
            </div>
            <div className={styles.number_description}>
              {t('sections.numbers.perDay')}
            </div>
          </div>
          <div className={styles.number_block}>
            <div className={styles.number}>
              <div className={styles.old}>21</div>
              <div className={styles.new}>7</div>
            </div>
            <div className={styles.number_description}>
              {t('sections.numbers.perWeek')}
            </div>
          </div>
          <div className={styles.number_block}>
            <div className={styles.number}>
              <div className={styles.old}>90</div>
              <div className={styles.new}>30</div>
            </div>
            <div className={styles.number_description}>
              {t('sections.numbers.perMonth')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BenefitsInNumbers;