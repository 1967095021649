import styles             from './autoprocess-section.module.scss';
import { useTranslation } from 'next-i18next';


function AutoProcess () {
  const { t } = useTranslation('landing');

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2 className="section-title">
          {t('sections.autoprocess.title')}
        </h2>
        <div className={styles.content}>
          <div className={styles.process}>
            <div className={styles.process_content}>
              <div className={styles.process_image}>
                <img src={'new_promo_page/images/process_1.png'}/>
              </div>
              <div className={styles.process_description}>
                <span>{t('sections.process.process_1.title')}</span>
                <ul>
                  <li>{t('sections.process.process_1.li_1')}</li>
                  <li>{t('sections.process.process_1.li_2')}</li>
                  <li>{t('sections.process.process_1.li_3')}</li>
                  <li>{t('sections.process.process_1.li_4')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.process}>
            <div className={styles.process_content}>
              <div className={styles.process_image}>
                <img src={'new_promo_page/images/process_2.png'}/>
              </div>
              <div className={styles.process_description}>
                <span>{t('sections.process.process_2.title')}</span>
                <ul>
                  <li>{t('sections.process.process_2.li_1')}</li>
                  <li>{t('sections.process.process_2.li_2')}</li>
                  <li>{t('sections.process.process_2.li_3')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.process}>
            <div className={styles.process_content}>
              <div className={styles.process_image}>
                <img src={'new_promo_page/images/process_3.png'}/>
              </div>
              <div className={styles.process_description}>
                <span>{t('sections.process.process_3.title')}</span>
                <ul>
                  <li>{t('sections.process.process_3.li_1')}</li>
                  <li>{t('sections.process.process_3.li_2')}</li>
                  <li>{t('sections.process.process_3.li_3')}</li>
                  <li>{t('sections.process.process_3.li_4')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.process}>
            <div className={styles.process_content}>

              <div className={styles.process_description}>
                <span>{t('sections.process.process_4.title')}</span>
                <ul>
                  <li>{t('sections.process.process_4.li_1')}</li>
                  <li>{t('sections.process.process_4.li_2')}</li>
                  <li>{t('sections.process.process_4.li_3')}</li>
                </ul>
              </div>
              <div className={styles.process_image}>
                <img src={'new_promo_page/images/process_5.png'}/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AutoProcess;
