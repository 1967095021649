import { useState }       from 'react';
import styles             from './howtostart-section.module.scss';
import { useTranslation } from 'next-i18next';

import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import { UserAgent }         from '@quentin-sommer/react-useragent';
import {
  Swiper,
  SwiperSlide,
}                            from 'swiper/react';
import { Pagination }        from 'swiper';


function HowToStartSection() {
  const {t} = useTranslation('landing');
  const [video, setVideo] = useState('registration');
  const [swiperInstance, setSwiperInstance] = useState(null);
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h3 className="section-title">
          {t('sections.howToStart.title')}
        </h3>

        <UserAgent computer>
          <div className={styles.content} style={{display: 'flex'}}>
            <div className={styles.oportunities}>
              <div className={styles.opportunity} onClick={() => setVideo('registration')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'registration' ? '#ff204e' : ''}}/>
                <span
                  style={{color: video === 'registration' ? '#ff204e' : ''}}>{t('sections.howToStart.oportunity_1')}</span>
              </div>
              <div className={styles.opportunity} onClick={() => setVideo('new_user')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'new_user' ? '#ff204e' : ''}}/>
                <span
                  style={{color: video === 'new_user' ? '#ff204e' : ''}}>{t('sections.howToStart.oportunity_2')}</span>
              </div>
              <div className={styles.opportunity} onClick={() => setVideo('fill_company')}>
                <PlayArrowOutlinedIcon style={{fill: video === 'fill_company' ? '#ff204e' : ''}}/>
                <span
                  style={{color: video === 'fill_company' ? '#ff204e' : ''}}>{t('sections.howToStart.oportunity_3')}</span>
              </div>
            </div>
            <div className={styles.video} style={{color: 'white'}}>
              <video autoPlay muted loop src={`/new_promo_page/videos/${video}.mp4`}>
                {/*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                {/*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
              </video>
            </div>
          </div>
        </UserAgent>
        <UserAgent mobile tablet>
          <div className={styles.content} style={{display: 'block', marginTop: '0'}}>
            <Swiper
              spaceBetween={40}
              modules={[Pagination]}
              pagination={{clickable: true, type: 'bullets'}}
              onSwiper={setSwiperInstance}
            >
              <SwiperSlide>
                <span>Пройдите регистрацию бесплатно</span>
                <div className="video-mobile">
                  {/*<video autoPlay muted loop src={`/new_promo_page/videos/registration.mp4`}>*/}
                  {/*<source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                  {/*  <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
                  {/*</video>*/}
                  <img src={'/images/sections/started-work-section/auth.png'} alt={'auth-image'}/>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <span>Добавьте сотрудников и назначьте им роли</span>
                <div className="video-mobile">
                  {/*<video autoPlay muted loop src={`/new_promo_page/videos/new_user.mp4`}>*/}
                  {/*  <source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                  {/*    <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
                  {/*</video>*/}
                  <img src={'/images/sections/started-work-section/addEmployee.png'} alt={'employee-image'}/>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <span>Заполните данные организации и начните работу</span>
                <div className="video-mobile">
                  {/*<video autoPlay muted loop src={`/new_promo_page/videos/fill_company.mp4`}>*/}
                  {/*  <source src={`/new_promo_page/videos/${video}.mp4`} type="video/mp4"/>*/}
                  {/*    <source src={`/new_promo_page/videos/${video}.webm`} type="video/webm"/>*/}
                  {/*</video>*/}
                  <img src={'/images/sections/started-work-section/fillDataGarage.png'} alt={'data-garage-image'}/>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </UserAgent>
      </div>

    </div>
  );
}

export default HowToStartSection;
