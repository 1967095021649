import { useTranslation }   from 'next-i18next';
import Link                 from 'next/link';
import styles               from './Demo.module.scss';
import { useProductEvents } from '../../../../../hooks';

const Demo = ({eventMetric}) => {
  const {t} = useTranslation('landing');

  return (
    <div className={styles.demo}>
      <Link href={'/#tarrifs'}>
        <div className={styles.demo__button}
          onClick={eventMetric}
        >
          <span>Готовы начать?</span>
        </div>
      </Link>

      <div className={styles.demo__text}>
        <span>{t('sections.forwhom.demo_text_1')}</span>
        <span>{t('sections.forwhom.demo_text_2')}</span>
      </div>
    </div>
  );
};

export default Demo;