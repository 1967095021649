import styles from './tariffs-section.module.scss';
import { useTranslation } from 'next-i18next';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { useRouter } from 'next/router';
import { useProductEvents } from 'hooks';
import cns from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'formsy-semantic-ui-react';
import { Button } from 'semantic-ui-react';
import {
  useEffect,
  useState,
} from 'react';
import UIPhoneInput from 'components/UI/PhoneInput';
import {
  OptimizelyExperiment,
  OptimizelyVariation,
  withOptimizely,
} from '@optimizely/react-sdk';


function TariffsSection({ optimizely }) {
  const { t } = useTranslation('landing');
  const events = useProductEvents();
  const router = useRouter();
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalData, setModalData] = useState();
  const { promo } = router.query;
  useEffect(() => {
    if (router.asPath.includes('#tarrifs')) {
      const tariffsSectionCoords = document.getElementById('tarrifs').getBoundingClientRect();
      window.scrollBy(0, tariffsSectionCoords.top);
    }
  }, [])

  return (
    <>
      <div style={{ visibility: visibleModal ? 'visible' : 'hidden' }}
        className={cns('header-modal', styles.nav_modal, { visible: visibleModal })}>
        <div className={styles.modal_container}>
          <div className={styles.nav_header_row}>
            <div className={styles.nav_title}>Оплата</div>
            <div
              className={styles.close_button}
              onClick={() => setVisibleModal(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <Form
            onSubmit={(data) => {
              const body = {
                'TerminalKey': '1649082037116',
                'Amount': modalData.price * modalData.count * 100,
                'OrderId': `${data.phone}-${modalData.price * modalData.count}-${Date.now()}`,
                'Receipt': {
                  'Phone': data.phone,
                  'Taxation': 'osn',
                  'Items': [
                    {
                      'Name': `Лицензионное вознаграждение за право использования программного продукта zen.car на ${modalData.count} месяца`,
                      'Quantity': 1,
                      'Amount': modalData.price * 100 * modalData.count,
                      'Price': modalData.price * 100 * modalData.count,
                      'Tax': 'none',
                    },
                  ],
                },
              };
              fetch('https://securepay.tinkoff.ru/v2/Init', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
              }).then(response => response.json())
                .then((data) => {
                  if (data.Success) {
                    window.open(data.PaymentURL, '_self');
                  }
                  setVisibleModal(false);
                });


            }
            }
          >
            <div className={styles.ui_rows} style={{ marginBottom: '20px' }}>
              <UIPhoneInput
                required
                name="phone"
                autoComplete="off"
                validations={{ isValidPhone: true }}
                validationErrors={{
                  isValidPhone: t('formErrors.incorrectPhonePattern'),
                }}
              />
            </div>
            <Button
              size={'large'}
              color={'red'}
              fluid
            >
              Оплатить
            </Button>
          </Form>
        </div>
      </div>
      <div id="tarrifs" className={styles.root}>
        <div className={styles.container}>
          <div className={styles.promo}>
            <div>
              <h3 className="section-title">
                {promo === 'bank_tochka' ? 'Тарифы*' : 'Тарифы'}
              </h3>
            </div>
            <div className={styles.promo_text} style={{ padding: '15px' }}>
              <If condition={promo === 'bank_tochka'}>
                <img style={{ marginTop: '20px', maxHeight: '75px' }} src="/new_promo_page/images/tochka.svg" />
              </If>
            </div>
          </div>



          <span className="section-subtitle">
            <span>{t('sections.tariffs.subtitle')}</span><span>при выборе любого тарифа!</span>
          </span>
          <div className={styles.tariffs}>
            <div className={styles.tariff}>
              <div className={styles.badge} style={{ opacity: promo === 'bank_tochka' ? '1' : '0' }}>
                <span>
                  {promo === 'bank_tochka' ? '-20%' : 'Самый популярный'}
                </span>
              </div>
              <div className={styles.price} style={{ textAlign: 'center' }}>
                <span
                  style={{ visibility: promo === 'bank_tochka' ? 'visible' : 'hidden' }}>{t('sections.tariffs.price_1')}</span>
                <span>{3990}</span>
                <span>₽/мес.</span>
              </div>
              <div className={styles.description}>

                <span>{t('sections.tariffs.payment_1_month')}</span>
              </div>
              <div
                className={styles.button}
                onClick={() => {
                  events.send('click_button__choose_tariff__workshop', { price: 3990 });
                  setModalData({
                    price: 3990,
                    count: 1,
                  });
                  setVisibleModal(true);
                }}
              >
                <span>{t('sections.tariffs.choose')}</span>
              </div>
            </div>
            <div className={styles.tariff}>
              <div className={styles.badge}>
                <span style={{ color: '#16293f' }}>
                  {promo === 'bank_tochka' ? '-20%' : 'Самый популярный'}
                </span>
              </div>
              <div className={styles.price}>
                <span
                  style={{ visibility: promo === 'bank_tochka' ? 'visible' : 'hidden' }}>{t('sections.tariffs.price_2')}</span>
                <span>{t('sections.tariffs.price_2')}</span>
                <span>{t('sections.tariffs.perMonth')}</span>
              </div>
              <div className={styles.description}>
                <span>{t('sections.tariffs.payment_6_month')}</span>
              </div>
              <div
                className={styles.button}
                onClick={() => {
                  events.send('click_button__choose_tariff__workshop', { price: 3490 });
                  setModalData({
                    price: 3490,
                    count: 3,
                  });
                  setVisibleModal(true);
                }}
              >
                <span>{t('sections.tariffs.choose')}</span>
              </div>

            </div>
            <div className={styles.tariff}>
              <div className={styles.badge}>
                <span>
                  {promo === 'bank_tochka' ? '-20%' : 'Самый выгодный⠀⠀'}
                </span>
              </div>
              <div className={styles.price}>
                <span
                  style={{ visibility: promo === 'bank_tochka' ? 'visible' : 'hidden' }}>{t('sections.tariffs.price_3')}</span>
                <span>{t('sections.tariffs.price_3')}</span>
                <span>{t('sections.tariffs.perMonth')}</span>
              </div>
              <div className={styles.description}>
                <span>{t('sections.tariffs.payment_12_month')}</span>
              </div>
              <div
                className={styles.button}
                onClick={() => {
                  events.send('click_button__choose_tariff__workshop', { price: 2990 });
                  setModalData({
                    price: 2990,
                    count: 12,
                  });
                  setVisibleModal(true);
                }}
              >
                <span>{t('sections.tariffs.choose')}</span>
              </div>
            </div>
          </div>
          {/* <If condition={promo === 'bank_tochka'}>
            <div style={{ marginTop: '20px' }}>
              <span>* Специально для клиентов Банка Точка</span>
            </div>
          </If> */}

          <div className={styles.features}>
            <span className={styles.feature_title}>{t('sections.tariffs.moreInformation')}</span>
            <div className={styles.features}>
              <div className={styles.features_list}>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.serviceRequest')}</span>
                </div>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.creationWorkOrders')}</span>
                </div>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.smsInform')}</span>
                </div>
              </div>
              <div className={styles.features_list}>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.booking')}</span>
                </div>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.schedule')}</span>
                </div>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.stock')}</span>
                </div>
              </div>
              <div className={styles.features_list}>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.timeStandard')}</span>
                </div>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.salary')}</span>
                </div>
                <div className={styles.feature}>
                  <CheckCircleOutlineOutlinedIcon />
                  <span>{t('sections.tariffs.features.analytics')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.try_free}>
            <div className={styles.free_wrapper}>
              <div className={styles.text}><span>Хотите попробовать прежде чем платить?</span></div>
              <OptimizelyExperiment experiment="zen_car_workshop_landing_try_demo_test">
                <OptimizelyVariation variation="variation_try_free">
                  <div className={styles.free_button} onClick={() => {
                    optimizely.track('click_main_action_button');
                    events.send('click_button__try_free_tariff__workshop');
                    window.open('/access?tab=signup', '_self');
                  }}>

                    <span>Подключиться бесплатно</span>
                  </div>
                </OptimizelyVariation>
                <OptimizelyVariation variation="variation_order_demo">
                  <div className={styles.free_button} onClick={() => {
                    optimizely.track('click_main_action_button');
                    events.send('click_button__demo_tariff__workshop');
                    window.location.href = "https://forms.yandex.ru/cloud/631064d428c2a452963f4a1c/"
                  }}>

                    <span>Запросить демо</span>
                  </div>
                </OptimizelyVariation>
                <OptimizelyVariation default>
                  <div className={styles.free_button} onClick={() => {
                    optimizely.track('click_main_action_button');
                    events.send('click_button__try_free_tariff__workshop');
                    window.open('/access?tab=signup', '_self');
                  }}>

                    <span>Подключиться бесплатно</span>
                  </div>
                </OptimizelyVariation>
              </OptimizelyExperiment>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withOptimizely(TariffsSection);