import {
  useEffect,
  useState,
}                                from 'react';
import styles                    from './feedback-section.module.scss';
import cns                       from 'classnames';
import { useRouter }             from 'next/router';
import { useTranslation }        from 'next-i18next';
import PhoneInTalkOutlinedIcon   from '@material-ui/icons/PhoneInTalkOutlined';
import PhoneCallbackOutlinedIcon from '@material-ui/icons/PhoneCallbackOutlined';
import ForumOutlinedIcon         from '@material-ui/icons/ForumOutlined';
import CloseIcon                 from '@material-ui/icons/Close';
import { Form }                  from 'formsy-semantic-ui-react';
import { Button }                from 'semantic-ui-react';
import { useProductEvents }      from 'hooks';
import { isProdAPI }             from 'helpers/helpers';

function Feedback() {
  const {t} = useTranslation('landing');
  const router = useRouter();
  const call = (router.asPath).indexOf('call');
  const [visibleModal, setVisibleModal] = useState(call !== -1);
  const events = useProductEvents();


  useEffect(() => {
    const ecsHandler = (evt) => {
      evt = evt || window.event;
      let isEscape = false;
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        setVisibleModal(false);
      }
    };

    document.addEventListener('keydown', ecsHandler);

    return () => {
      document.removeEventListener('keydown', ecsHandler);
    };
  }, []);

  useEffect(() => {
    const links = document.querySelectorAll('header a');

    const linkClickHandler = () => {
      setVisibleModal(false);
      document.body.classList.remove('disable-scroll');
    };

    links.forEach((elem) => {
      elem.addEventListener('click', linkClickHandler);
    });

    return () => {
      links.forEach((elem) => {
        elem.removeEventListener('click', linkClickHandler);
      });
    };
  }, []);

  const onOpenNavModalClick = (modalName) => {
    document.body.classList.add('disable-scroll');
    setVisibleModal(true);
  };


  const onCloseNavModalClick = () => {
    document.body.classList.remove('disable-scroll');
    setVisibleModal(false);
  };

  return (
    <>
      <div className={styles.root} id={'call'}>
        <div className={cns(styles.modal_overlay, {visible: visibleModal})}/>
        <div className={styles.container}>
          <div className="section-title">
            {t('sections.feedback.title')}
          </div>
          <div className="section-subtitle">
            {t('sections.feedback.subtitle')}
          </div>
          <div className={styles.content}>
            <a className={styles.plate} href={'tel:88007770077'}>
              <PhoneInTalkOutlinedIcon/>
              <span>
              {t('sections.feedback.tell')}
            </span>
            </a>
            <div
              className={styles.plate}
              onClick={() => {
                onOpenNavModalClick();
                events.send('click_call__button_on_main_page__workshop');
              }
              }
            >
              <PhoneCallbackOutlinedIcon/>
              <span>
              {t('sections.feedback.call')}
            </span>
            </div>
            <div className={styles.plate} onClick={() => {
              try {
                document.querySelector('#supportTrigger').click();
              } catch {
                document.querySelector('.support-close').click();
              }
            }}>
              <ForumOutlinedIcon/>
              <span>
              {t('sections.feedback.chat')}
            </span>
            </div>
          </div>
          <div className={styles.modes}>
            <div className={styles.mode}>
              {t('sections.feedback.mode')}
            </div>
            <div
              className={styles.mode}

            >
              {t('sections.feedback.modeTime')}
            </div>
            <div className={styles.mode}>
              {t('sections.feedback.modeWeek')}
            </div>
          </div>
        </div>
      </div>
      <div style={{visibility: visibleModal ? 'visible' : 'hidden'}}
           className={cns('header-modal', styles.nav_modal, {visible: visibleModal})}>
        <div className={styles.modal_container}>
          <div className={styles.nav_header_row}>
            <div className={styles.nav_title}>Заказ звонка</div>
            <div
              className={styles.close_button}
              onClick={() => onCloseNavModalClick()}
            >
              <CloseIcon/>
            </div>
          </div>
          <Form
            onSubmit={async (data) => {
              await fetch(`/api/roistat?${new URLSearchParams(data).toString()}`);
              events.sendTo(['amplitude'], 'send_call_back_on_main_page__workshop');
              events.sendTo(['ym'], 'callBack');
              if (isProdAPI() && typeof window !== 'undefined') {
                if (typeof window.fbq === 'function') {
                  window.fbq('track', 'lead');
                }
              }
              onCloseNavModalClick();
            }
            }
          >
            <div className={styles.ui_rows}>
              <Form.Input
                name={'name'}
                placeholder={'Ваше имя и фамилия'}
              />
              <Form.Input
                name={'phone'}
                placeholder={'Номер телефона'}
              />
            </div>

            <Form.TextArea
              name={'question'}
              placeholder={'Ваш вопрос'}
            />
            <Button
              size={'large'}
              color={'red'}
            >
              Заказать
            </Button>
          </Form>
        </div>
      </div>
    </>

  );
}

export default Feedback;
