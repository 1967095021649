import Demo                 from '../../UI/Demo';
import styles               from './Statistics.module.scss';
import { useProductEvents } from 'hooks';

const Statistics = () => {
  const events = useProductEvents();

  return (
    <section className={styles.statistics}>
      <h2 className={`section-title ${styles.statistics__title}`}>Приводим клиентов</h2>
      <div className={styles.statistics__subtitle}>
        <span>Автовладельцы записываются в СТО через мобильное&nbsp;</span>
        <span>приложение, сайт ZEN.CAR или государственные&nbsp;</span>
        <span>сервисы-партнеры</span>
      </div>
      <div className={styles['statistics__img-wrapper']}>
        <img className={styles.statistics__img} src={'/images/statistics.svg'} alt={'Statistics'}/>
        <img className={styles['statistics__img-mobile']} src={'/images/statistics-mobile.svg'}/>
        <img className={styles['statistics__img-mobile']} src={'/images/statistics-rate.svg'}/>
      </div>
      <Demo eventMetric={() => events.send('click_button__ready_start_in_promo_section__workshop')}/>
    </section>
  );
};

export default Statistics;