import { useDispatch } from 'react-redux';
import SectionMain from 'components/Site/NewLanding/Sections/Main';
import PromoTextSection from 'components/Site/NewLanding/Sections/PromoText';
import ViewTabsSection from 'components/Site/NewLanding/Sections/ViewTabs';
import ProblemsSection from 'components/Site/NewLanding/Sections/Problems';
import ForWhomSection from 'components/Site/NewLanding/Sections/ForWhomSection';
import AutoProcess from 'components/Site/NewLanding/Sections/AutoProcess';
import Statistics from 'components/Site/NewLanding/Sections/Statistics';
import MobileAppSection from '../../../components/Site/NewLanding/Sections/MobileAppSection';
import BenefitsInNumbers from 'components/Site/NewLanding/Sections/BenefitInNumbers';
import ReviewsSection from 'components/Site/NewLanding/Sections/Reviews';
import TariffsSection from 'components/Site/NewLanding/Sections/Tariffs';
import ReadyStartSection from 'components/Site/NewLanding/Sections/ReadyStart';
import PartnersLogosSection from 'components/Site/NewLanding/Sections/PartnersLogos';
import HowToStart from 'components/Site/NewLanding/Sections/HowToStart';
import Feedback from 'components/Site/NewLanding/Sections/Feedback';
import styles from './new-landing-view.module.scss';
import HistoryCompany from '../../../components/Site/NewLanding/Sections/HistoryCompany';
import PartnersSection from '../../../components/Site/NewLanding/Sections/PartnersSection';

const NewLandingView = () => {
  const dispatch = useDispatch();

  /*useEffect(() => {
    setTimeout(() => {
      dispatch(toggleBlackFridayModal(true));
    }, 1000);
  }, []);*/
  return (
    <div className={styles.root}>
      <script type="text/javascript" src="/vendors/verbox.js" />
      <script type="text/javascript" src="/vendors/amo.js" />
      <SectionMain />
      <ViewTabsSection />
      <AutoProcess />
      <PromoTextSection />
      <Statistics />
      <MobileAppSection />
      <ForWhomSection />
      <TariffsSection />
      <ReviewsSection />

      <HistoryCompany />
      <PartnersSection />
      {/*<ProblemsSection/>*/}
      {/*<BenefitsInNumbers/>*/}
      {/*<PartnersLogosSection/>*/}
      {/*<HowToStart/>*/}
      <ReadyStartSection />
      <Feedback />
      {/*<SaleActionModal/>*/}
    </div>
  );
};

export default NewLandingView;